var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form3",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"ma-3",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-6 ml-4"},[_c('span',{staticClass:"subtitle-1"},[_vm._v(" Hier kannst du ein Titelbild für deinen Heimabend einfügen. ")])]),_c('v-row',{staticClass:"ma-4"},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-image","label":"Neues Titelbild einfügen","required":""},model:{value:(_vm.addImage),callback:function ($$v) {_vm.addImage=$$v},expression:"addImage"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s('Gib deiner Heimabend-Idee eine passende Überschrift.')+" ")])])],1)],2)],1),(_vm.imageUuid)?_c('v-row',[_c('v-img',{staticClass:"ma-5",attrs:{"max-height":"300","aspect-ratio":"2","src":_vm.getImageLink}})],1):_vm._e(),(_vm.addImage)?_c('div',[_c('v-row',{staticClass:"ma-4"},[_c('v-file-input',{attrs:{"label":"Datei","show-size":"","prepend-icon":"mdi-image"},on:{"change":_vm.uploadImage}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2874084739)},[_c('span',[_vm._v(" "+_vm._s('Gib deiner Heimabend-Idee eine passende Überschrift.')+" ")])])],1)],2)],1),(_vm.showCropper)?_c('v-row',[_c('cropper',{ref:"cropper",staticClass:"example-cropper",attrs:{"stencil-props":{
            aspectRatio: 2,
          },"src":_vm.imageData}})],1):_vm._e(),(_vm.isLoading)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_vm._v(" Uploading "),_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100","color":"primary"}})],1):_vm._e(),(_vm.showCropper)?_c('v-row',[_c('v-btn',{staticClass:"ma-3",attrs:{"color":"success"},on:{"click":_vm.onUploadImageClick}},[_c('v-icon',[_vm._v("mdi-upload")]),_vm._v(" Diesen Ausschnitt Hochladen ")],1)],1):_vm._e()],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.addImage),expression:"addImage"}]},[(false)?_c('v-row',{staticClass:"ma-4"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-image","hint":"This field uses counter prop","label":"Foto-ID","disabled":""},model:{value:(_vm.tempData.imageId),callback:function ($$v) {_vm.$set(_vm.tempData, "imageId", $$v)},expression:"tempData.imageId"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2874084739)},[_c('span',[_vm._v(" "+_vm._s('Gib deiner Heimabend-Idee eine passende Überschrift.')+" ")])])],1)],2)],1):_vm._e(),(false)?_c('v-row',{staticClass:"ma-4"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-image","hint":"This field uses counter prop","label":"UUID","disabled":""},model:{value:(_vm.imageUuid),callback:function ($$v) {_vm.imageUuid=$$v},expression:"imageUuid"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2874084739)},[_c('span',[_vm._v(" "+_vm._s('Gib deiner Heimabend-Idee eine passende Überschrift.')+" ")])])],1)],2)],1):_vm._e(),(_vm.imageUuid)?_c('div',[_c('v-row',{staticClass:"ma-4"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-image","error-messages":_vm.descriptionErrors,"label":"Bild Beschreibung"},model:{value:(_vm.tempData.description),callback:function ($$v) {_vm.$set(_vm.tempData, "description", $$v)},expression:"tempData.description"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2874084739)},[_c('span',[_vm._v(" "+_vm._s('Gib deiner Heimabend-Idee eine passende Überschrift.')+" ")])])],1)],2)],1),_c('v-row',{staticClass:"ma-4"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-image","error-messages":_vm.photographerNameErrors,"label":"Fotograf_in"},model:{value:(_vm.tempData.photographerName),callback:function ($$v) {_vm.$set(_vm.tempData, "photographerName", $$v)},expression:"tempData.photographerName"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2874084739)},[_c('span',[_vm._v(" "+_vm._s('Gib deiner Heimabend-Idee eine passende Überschrift.')+" ")])])],1)],2)],1),_c('v-row',{staticClass:"ma-4"},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-image","error-messages":_vm.privacyConsentErrors,"label":"Datenschutz","required":""},model:{value:(_vm.tempData.privacyConsent),callback:function ($$v) {_vm.$set(_vm.tempData, "privacyConsent", $$v)},expression:"tempData.privacyConsent"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2874084739)},[_c('span',[_vm._v(" "+_vm._s('Entspricht das Bild den Datenschutzrichtlinien.' + 'Es darf keine Bilder enthaltenn.')+" ")])])],1)],2)],1),_c('v-row',{staticClass:"ma-4"},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-image","error-messages":_vm.isOpenSourceErrors,"label":"Bildrechte","required":""},model:{value:(_vm.tempData.isOpenSource),callback:function ($$v) {_vm.$set(_vm.tempData, "isOpenSource", $$v)},expression:"tempData.isOpenSource"}},[_c('template',{slot:"append"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,2874084739)},[_c('span',[_vm._v(" "+_vm._s('Sind die Bildrechte Open Source?')+" ")])])],1)],2)],1)],1):_vm._e()],1),_c('v-row',{staticClass:"ma-3",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"ma-1",on:{"click":function($event){return _vm.prevStep()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chevron-left ")]),_vm._v(" Zurück ")],1),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" Weiter "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }