var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form2",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{staticClass:"mt-6 ml-2 text-left"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('editor',{attrs:{"api-key":"3syby8pyhylkqgpeyskfycuy0pc47gvppx1311cqijn05wxu","initialValue":"<p>Ihr könnt</p>","rules":_vm.rules.description,"required":"","init":{
            height: 500,
            language: 'de',
            image_advtab: true,
            image_title: true,
            automatic_uploads: true,
            file_picker_types: 'image',
            image_dimensions: true,
            menubar: 'edit insert view format tools table',
            plugins: [
              'advlist autolink lists link image preview',
              'searchreplace visualblocks code',
              'table paste wordcount' ],
            toolbar:
              'formatselect | bold italic underline | forecolor | \
              bullist numlist | removeformat | undo redo | image',
            file_picker_callback: _vm.imageUploadhandler,
            relative_urls: false, // eslint-disable-line
            remove_script_host: false, // eslint-disable-line
            style_formats: [
              { title: 'Bilder' },
              {
                title: 'Bild Links',
                selector: 'img',
                styles: {
                  float: 'left',
                  margin: '10px 10px 10px 10px',
                },
              } ],
          }},on:{"onInit":function($event){return _vm.onInit()}},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}}),(_vm.getCustomText !== 'Ok')?_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message customerRequired pl-3 ma-1"},[_vm._v(" "+_vm._s(_vm.getCustomText)+" ")])])])]):_vm._e(),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"color":"primary","indeterminate":""}})],1)],1),_c('v-row',{staticClass:"mt-6 ml-2 text-left"},[_c('span',{staticClass:"subtitle-2"},[_vm._v(" Damit die Idee übersichtlicher dargestellt wird möchten wir dich bitten, deinen Beschreibungstext gedanklich in zwei Abschnitte zu gliedern: Im ersten Abschnitt fass bitte deine Heimabend-Idee kurz zusammen und im zweiten Abschnitt kannst du genauer beschreiben, worum es sich handelt. "),_c('br'),_c('br'),_c('i',[_vm._v("Beachte: In dem nächsten Schritt hast du die Möglichkeit deine Materialliste zu erstellen, sodass du dein Material nicht im Beschreibungstext auflisten musst.")]),_c('br'),_vm._v(" Viel Spaß dabei! ")])]),_c('v-row',{staticClass:"ma-3",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"ma-1",on:{"click":function($event){return _vm.prevStep()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chevron-left ")]),_vm._v(" Zurück ")],1),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" Weiter "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-right ")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.nextStep(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-debug-step-over ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s('Schritt überspringen')+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }